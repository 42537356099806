<template>
    <div
        ref="scrubber"
        class="relative cursor-pointer select-none rounded bg-slate-400"
        :style="{
            'height': simple ? '.5rem' : '.25rem'
        }"
        @mousedown="scrubbing = true"
    >
        <div class="relative size-full overflow-hidden rounded">
            <div
                class="absolute start-0 top-0 size-full rounded bg-slate-500"
                :class="{
                    'opacity-25': !simple,
                    'opacity-50': simple,
                }"
                :style="{ transform: `translateX(${secondary / max * 100 - 100}%)` }"
            />
            <div
                class="relative size-full rounded bg-main1"
                :class="{
                    'opacity-50': !simple,
                }"
                :style="{ transform: `translateX(${(value / max * 100 - 100)}%)` }"
            />
        </div>
        <div
            v-if="!simple"
            class="relative size-full"
        >
            <div
                class="absolute size-4 rounded-full bg-main1"
                :style="{
                    left: `${value / max * 100}%`,
                    'margin-top': '-.625rem',
                    'margin-left': '-.5rem',
                }"
            />
        </div>

        <div
            v-if="!simple"
            class="absolute opacity-0 hover:opacity-100"
            :class="{'opacity-100': scrubbing}"
            :style="{ inset: 0 }"
        >
            <slot
                :pending-value="pendingValue"
                :position="`${Math.max(0, Math.min(elementX, elementWidth))}px`"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useEventListener, useMouseInElement, useVModel, } from '@vueuse/core'
import { ref, watch, } from 'vue-demi'

const props = defineProps({
    modelValue: { 
        type: Number, 
        required: true, 
    },
    min: { 
        type: Number,
        default: 0, 
    },
    max: { 
        type: Number, 
        default: 100, 
    },
    secondary: { 
        type: Number, 
        default: 0, 
    },
    simple: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits([ 'update:modelValue', ])
const scrubber = ref()
const scrubbing = ref(false)
const pendingValue = ref(0)

useEventListener('mouseup', () => scrubbing.value = false)

const value = useVModel(props, 'modelValue', emit)
const { elementX, elementWidth, } = useMouseInElement(scrubber)

watch([ scrubbing, elementX, ], () => {
    const progress = Math.max(0, Math.min(1, (elementX.value) / elementWidth.value))
    pendingValue.value = progress * props.max
    if (scrubbing.value) {
        value.value = pendingValue.value
    }
})
</script>

<style lang="scss" scoped>
.scrubber-pending:hover {
    opacity: 100% !important;
}
</style>
