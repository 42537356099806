<script setup lang="ts">
import { ToastTitle, type ToastTitleProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<ToastTitleProps & { class?: string }>()
</script>

<template>
    <ToastTitle
        v-bind="props"
        :class="cn('rfs:text-sm rfs:font-medium', props.class)"
    >
        <slot />
    </ToastTitle>
</template>
