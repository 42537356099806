import {useMediaControls,} from '@vueuse/core'
import {defineStore,} from 'pinia'
import {ref,} from 'vue'

export const useGlobalAudioPlayer = defineStore('global-audio', () => {
    const audioElement = ref<HTMLAudioElement|null>(null)
    const src = ref<string|null>(null)
    const title = ref<string|null>(null)
    const playerId = ref<string|null>(null)
    const { playing, volume, currentTime, duration, buffered, rate,} = useMediaControls(audioElement, {src,})
    const loaded = ref<boolean>(false)
    const returnLink = ref<string|null>(null)

    let tmpSrc = ''
    let tmpTitle = ''

    function preloadAudio (id: string, url: string, t: string, link: string) {
        playerId.value = id
        tmpSrc = url
        tmpTitle = t
        returnLink.value = link
        if (!loaded.value) {
            loaded.value = true
        } else {
            src.value = tmpSrc
            title.value = tmpTitle
            playing.value = false
            setTimeout(() => { playing.value = true }, 300)
        }
    }

    function loadAudio (element: HTMLAudioElement) {
        audioElement.value = element
        src.value = tmpSrc
        title.value = tmpTitle
        playing.value = false
        setTimeout(() => { playing.value = true }, 300)
    }

    function togglePlay () {
        playing.value = !playing.value
    }

    function sermonPlaying (id: string) {
        if (!playerId.value) {
            return false
        }
        if (playerId.value.startsWith(`sermon-${id}-`)) {
            return true
        }

        return false
    }

    function setTime (seconds: number, play: boolean = false) {
        currentTime.value = seconds
        if (play) {
            playing.value = true
        }
    }

    function reset () {
        src.value = null
        title.value = null
        tmpSrc = null
        tmpTitle = null
        audioElement.value = null
        loaded.value = false
        playerId.value = null
    }

    return {
        audioElement,
        src,
        playing,
        volume,
        currentTime,
        duration,
        buffered,
        rate,
        title,

        loadAudio,
        preloadAudio,
        reset,
        togglePlay,
        sermonPlaying,
        setTime,

        returnLink,
        playerId,
        loaded,
    }
})

const definedStores = new Map<string, ReturnType<typeof defineAudioPlayerStore>>()
const audioPlayerStoreFactory = (audioPlayerId: string) => {
    if (!definedStores.has(audioPlayerId)) {
        definedStores.set(audioPlayerId, defineAudioPlayerStore(audioPlayerId))
    }

    return definedStores.get(audioPlayerId) as ReturnType<typeof defineAudioPlayerStore>
}

const defineAudioPlayerStore = <Id extends string>(audioPlayerId: Id) => defineStore(`audio-player/${audioPlayerId}`, () => {
    const audioElement = ref<HTMLAudioElement|null>(null)
    const src = ref<string|null>(null)
    const { playing, volume, currentTime, duration, buffered, rate,} = useMediaControls(audioElement, {src,})

    function loadAudio (element: HTMLAudioElement, url: string) {
        audioElement.value = element
        src.value = url
    }

    return {
        src,
        playing,
        volume,
        currentTime,
        duration,
        buffered,
        rate,

        loadAudio,
    }
})

export const useAudioPlayer = <Id extends string>(audioPlayerId: Id) => {
    return audioPlayerStoreFactory(audioPlayerId)()
}
