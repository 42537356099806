<template>
    <Segment class="bg-pink3">
        <div class="flex-col items-center gap-y-4 lg:flex lg:flex-row">
            <div class="order-1 mt-4 flex flex-col items-start gap-y-2 lg:mt-0 lg:w-1/2">
                <img
                    src="@/static/img/immanuel-wetzlar-logo.png"
                    class="mb-4 block h-14"
                    alt="Immanuel Wetzlar Logo"
                >

                <div class="flex items-center gap-1">
                    <MapPin class="size-5" />
                    <div>
                        Elisabethenstraße 23,
                        35576 Wetzlar
                    </div>
                </div>
                <div class="flex items-center gap-1">
                    <Clock class="size-5" />
                    <div>
                        Gottesdienst sonntags um 09:30 und 11:30 Uhr
                    </div>
                </div>
                <div class="flex items-center gap-1">
                    <p>
                        <Phone class="size-5" />
                    </p><div>
                        +49 6441 8057988
                    </div>
                </div>

                <div class="mt-4 flex flex-wrap gap-2">
                    <Button
                        :as="TransitionLink"
                        variant="outline-dark"
                        :href="$route('vue_page.view', 'anfahrt')"
                    >
                        Anfahrt
                    </Button>
                    <Button
                        :as="TransitionLink"
                        variant="outline-dark"
                        :href="$route('vue_page.view', 'kontakt')"
                    >
                        Kontaktformular
                    </Button>
                </div>
            </div>

            <div class="order-2 items-center py-4 lg:flex lg:w-1/2 lg:p-4">
                <div class="aspect-video w-full">
                    <iframe
                        v-if="googleMaps"
                        width="100%"
                        height="100%"
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyARV0Kwb1fDF3B_HREbKVqf0ikeiPB5yxE&q=Immanuel-Gemeinde+Wetzlar,Elisabethenstrasse+23,35576+Wetzlar"
                        loading="lazy"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        title="Google Maps"
                    />
                    <div
                        v-else
                        class="flex size-full items-center justify-center bg-pink4"
                    >
                        <button
                            class="underline"
                            @click="consentStore.setGoogleMaps(true)"
                        >
                            Google Maps erlauben
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Segment>
    <Segment class="bg-pink3 pt-0">
        <div class="text-center">
            &copy; 2024 Immanuel-Gemeinde Wetzlar,
            <a
                class="inline-flex items-center gap-2 underline underline-offset-4"
                target="_blank"
                href="https://pglaum.de"
            >
                Philipp Glaum
            </a>
        </div>
        <div class="mt-4 flex flex-wrap items-center justify-center gap-2">
            <TransitionLink
                :href="$route('vue_page.view', 'impressum')"
                class="underline underline-offset-4"
            >
                Impressum
            </TransitionLink>
            <TransitionLink
                :href="$route('vue_page.view', 'datenschutz')"
                class="underline underline-offset-4"
            >
                Datenschutz
            </TransitionLink>
            <button
                class="underline underline-offset-4"
                @click="loadCookieConsent || true ? dialogStore.showDialog({}, 'cookie-consent') : toast({description: 'Cookie-Einstellungen sind im Entwicklermodus deaktiviert.', variant: 'info'})"
            >
                Einstellungen
            </button>
        </div>
    </Segment>
</template>

<script setup lang="ts">
import {Clock, MapPin, Phone,} from 'lucide-vue-next'
import {getActivePinia, storeToRefs,} from 'pinia'
import {ref,} from 'vue'

import {useDialogStore,} from '@/js/components/advanced/dialog/dialog'
import Button from '@/js/components/ui/button/Button.vue'
import {useToast,} from '@/js/components/ui/toast'
import Segment from '@/js/modules/main/Segment.vue'
import {useConsentStore,} from '@/js/shared/consent/consent'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'

const dialogStore = useDialogStore(getActivePinia())
const consentStore = useConsentStore(getActivePinia())
const {googleMaps,} = storeToRefs(consentStore)

const {toast,} = useToast()

const loadCookieConsent = ref(import.meta.env.VITE_APP_ENV === 'production')
</script>
