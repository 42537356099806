<script setup lang="ts">
import { DialogTitle, type DialogTitleProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<DialogTitleProps & { class?: string }>()
</script>

<template>
    <DialogTitle
        :class="cn('rfs:text-lg rfs:font-medium text-slate-950 dark:text-slate-50', props.class)"
        v-bind="props"
    >
        <slot />
    </DialogTitle>
</template>
