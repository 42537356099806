export const clone = (obj: Object) => {
    return JSON.parse(JSON.stringify(obj))
}

export const isMac = () => {
    return navigator.platform.indexOf('Mac') == 0 || navigator.platform === 'iPhone'
}

export const humanFileSize = (bytes: number, si: boolean = false, dp = 1) => {
    const thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    const units = si
        ? [ 'kB','MB','GB','TB','PB','EB','ZB','YB', ]
        : [ 'KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB', ]
    let u = -1
    const r = 10 ** dp
    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
}

export class ServiceWorkerRegError extends Error {
    constructor (message: string) {
        super(message)
        this.name = 'ServiceWorkerRegError'
    }
}
