<script setup lang="ts">
import { cva, } from 'class-variance-authority'
import { X, } from 'lucide-vue-next'
import {
    DialogClose,
    DialogContent,
    type DialogContentEmits,
    type DialogContentProps,
    DialogOverlay,
    DialogPortal,
    useEmitAsProps,
} from 'radix-vue'

import { cn, } from '@/js/lib/utils'

interface SheetContentProps extends DialogContentProps {
    side?: 'left' | 'right' | 'top' | 'bottom'
    class?: string
}

const props = defineProps<SheetContentProps>()

const emits = defineEmits<DialogContentEmits>()

const emitsAsProps = useEmitAsProps(emits)

const sheetVariants = cva(
    'fixed z-50 gap-4 bg-white p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out',
    {
        variants: {
            side: {
                top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
                bottom:
                    'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
                left: 'inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm',
                right:
                    'inset-y-0 right-0 h-full  w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm',
            },
        },
        defaultVariants: {
            side: 'right',
        },
    }
)
</script>

<template>
    <DialogPortal>
        <DialogOverlay
            class="
                fixed
                inset-0
                z-50
                bg-white/80
                backdrop-blur-sm
                data-[state=open]:animate-in
                data-[state=closed]:animate-out
                data-[state=closed]:fade-out-0
                data-[state=open]:fade-in-0
                dark:bg-slate-950/80
                "
        />
        <DialogContent
            :class="cn(sheetVariants({ side: props.side }), props.class)"
            v-bind="{ ...props, ...emitsAsProps }"
        >
            <slot />

            <DialogClose
                v-if="false"
                class="
                    absolute
                    right-4
                    top-4
                    rounded-md
                    p-0.5
                    transition-colors
                    hover:bg-slate-100
                    dark:hover:bg-slate-800
                    "
            >
                <X class="size-4 text-slate-500 dark:text-slate-400" />
            </DialogClose>
        </DialogContent>
    </DialogPortal>
</template>
