const _fetch = function (url: string, data = null, method = 'GET', fileUpload = false) {
    let options = {
        method,
        headers: {
            'X-PureApi': 'true',
        },
    }

    if (fileUpload) {
        options.body = data
    } else {
        options.headers['Content-Type'] = 'application/json; charset=UTF-8'
        if (data) {
            options.body = JSON.stringify(data)
        }
    }

    return fetch(url, options).then(async (response) => {
        if (response.status === 204) {
            return {}
        }

        let json = await response.json()
        if (typeof json === 'string') {
            json = JSON.parse(json)
        }

        if ([ 401, 400, ].includes(response.status)) {
            throw new Error(json.error ?? 'Zugriff verweigert!')
        }

        return json
    })
}

const Api = {
    get: (url: string) => _fetch(url),
    post: (url: string, data: Object) => _fetch(url, data, 'POST'),
    patch: (url: string, data: Object) => _fetch(url, data, 'PATCH'),
    delete: (url: string) => _fetch(url, null, 'DELETE'),
    postFile: (url: string, data: Object) => _fetch(url, data, 'POST', true),
}

export {
    Api,
}
