<script setup lang="ts">
import { MenubarTrigger, type MenubarTriggerProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<MenubarTriggerProps & { class?: string }>()
</script>

<template>
    <MenubarTrigger
        v-bind="props"
        :class="
            cn(
                'flex cursor-default select-none items-center rounded px-3 py-1.5 text-sm font-medium outline-none focus:bg-slate-100 focus:text-slate-900 data-[state=open]:bg-slate-100 data-[state=open]:text-slate-900 dark:focus:bg-slate-800 dark:focus:text-slate-50 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-50',
                props.class,
            )
        "
    >
        <slot />
    </MenubarTrigger>
</template>
