import {cva, } from 'class-variance-authority'

export {default as Alert, } from './Alert.vue'
export {default as AlertDescription, } from './AlertDescription.vue'
export {default as AlertTitle, } from './AlertTitle.vue'

export const alertVariants = cva(
  'relative w-full rounded-lg border border-slate-200 p-4 dark:border-slate-800 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-slate-950 dark:[&>svg]:text-slate-50 [&>svg~*]:pl-7',
  {
    variants: {
      variant: {
        default: 'bg-white text-slate-950 dark:bg-slate-950 dark:text-slate-50',
        warning:
          'border-yellow-500 bg-yellow-400/50 text-yellow-900 dark:border-yellow-900/50 dark:text-yellow-900 [&>svg]:text-yellow-900 dark:[&>svg]:text-yellow-900',
        destructive:
          'border-red-500/50 bg-red-100 text-red-500 dark:border-red-900/50 dark:text-red-900 [&>svg]:text-red-500 dark:[&>svg]:text-red-900',
        pink3:
          'border-pink3 bg-pink3/50 text-slate-900 dark:border-pink3 dark:bg-pink3/50 dark:text-slate-50 [&>svg]:text-pink3 dark:[&>svg]:text-pink3',
        main1:
          'border-main1 bg-main1/50 text-slate-900 dark:border-main1 dark:bg-main1/50 dark:text-slate-50 [&>svg]:text-main1 dark:[&>svg]:text-main1',
        success:
          'border-emerald-500 bg-emerald-500/50 text-slate-900 dark:border-emerald-500 dark:bg-emerald-500/50 dark:text-slate-50 [&>svg]:text-emerald-500 dark:[&>svg]:text-emerald-500',
        slate:
          'border-slate-400 bg-slate-100/50 text-slate-900 dark:border-slate-400 dark:bg-slate-800/50 dark:text-slate-50 [&>svg]:text-slate-400 dark:[&>svg]:text-slate-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
