import code from '@/js/mdc/parser/handlers/code'
import containerComponent from '@/js/mdc/parser/handlers/containerComponent'
import emphasis from '@/js/mdc/parser/handlers/emphasis'
import html from '@/js/mdc/parser/handlers/html'
import image from '@/js/mdc/parser/handlers/image'
import inlineCode from '@/js/mdc/parser/handlers/inlineCode'
import link from '@/js/mdc/parser/handlers/link'
import list from '@/js/mdc/parser/handlers/list'
import paragraph from '@/js/mdc/parser/handlers/paragraph'
import strong from '@/js/mdc/parser/handlers/strong'

export default {
  emphasis,
  code,
  link,
  paragraph,
  html,
  list,
  image,
  strong,
  inlineCode,
  containerComponent,
}
