import {cva, } from 'class-variance-authority'

export {default as Button, } from './Button.vue'

export const buttonVariants = cva(
    'inline-flex items-center justify-center gap-2 break-words rounded-md text-left text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
    {
        variants: {
            variant: {
                none: '',
                default: 'bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90',
                primary: 'bg-main1 text-slate-50 hover:bg-main1/90 dark:bg-main1 dark:text-slate-50 dark:hover:bg-main1/90',
                secondary: 'bg-main2 text-slate-50 hover:bg-main2/90 dark:bg-main2 dark:text-slate-50 dark:hover:bg-main2/90',
                destructive:
                    'bg-red-500 text-slate-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/90',
                outline:
                    'border border-slate-200 bg-transparent hover:bg-slate-100 hover:text-slate-900 dark:border-slate-800 dark:bg-slate-950 dark:hover:bg-slate-800 dark:hover:text-slate-50',
                'outline-primary':
                    'border border-main1 bg-transparent text-main1 hover:bg-main1 hover:text-slate-50 dark:border-main1 dark:bg-main1 dark:hover:bg-main1 dark:hover:text-slate-50',
                'outline-secondary':
                    'border border-main2 bg-white text-main2 hover:bg-main2 hover:text-slate-50 dark:border-main2 dark:bg-main2 dark:hover:bg-main2 dark:hover:text-slate-50',
                'outline-dark':
                    'border border-slate-900 bg-transparent text-slate-900 hover:bg-slate-900 hover:text-slate-50 dark:border-slate-50 dark:bg-transparent dark:hover:bg-slate-50 dark:hover:text-slate-900',
                'outline-destructive':
                    'border border-red-500 bg-transparent text-red-500 hover:bg-red-500 hover:text-slate-50 dark:border-red-900 dark:bg-red-900 dark:hover:bg-red-900 dark:hover:text-slate-50',
                'outline-pink4':
                    'border border-pink4 bg-transparent text-pink4 hover:bg-pink4 hover:text-black dark:border-pink4 dark:bg-pink4 dark:hover:bg-pink4 dark:hover:text-slate-50',
                'outline-white':
                    'border border-white bg-transparent text-white hover:bg-white hover:text-black dark:border-white dark:bg-white dark:hover:bg-white dark:hover:text-slate-50',
                slate:
                    'bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
                ghost: 'hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50',
                'ghost-dark': 'text-slate-100 hover:bg-main2/30 hover:text-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50',
                link: 'text-slate-900 underline-offset-4 hover:underline dark:text-slate-50',
                success: 'bg-green-500 text-slate-50 hover:bg-green-500/90 dark:bg-green-900 dark:text-slate-50 dark:hover:bg-green-900/90',
                warning: 'bg-yellow-500 text-slate-50 hover:bg-yellow-500/90 dark:bg-yellow-900 dark:text-slate-50 dark:hover:bg-yellow-900/90',
            },
            size: {
                default: 'px-4 py-2',
                sm: 'rounded-md px-3 py-1 text-sm',
                lg: 'rounded-md px-8',
                icon: 'size-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)
