import {cva, } from 'class-variance-authority'

export {default as Toast, } from './Toast.vue'
export {default as ToastAction, } from './ToastAction.vue'
export {default as ToastClose, } from './ToastClose.vue'
export {default as ToastDescription, } from './ToastDescription.vue'
export {default as Toaster, } from './Toaster.vue'
export {default as ToastProvider, } from './ToastProvider.vue'
export {default as ToastTitle, } from './ToastTitle.vue'
export {default as ToastViewport, } from './ToastViewport.vue'
export {toast, useToast, } from './use-toast'

export const toastVariants = cva(
  'group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border border-slate-200 p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full dark:border-slate-800 data-[state=open]:sm:slide-in-from-bottom-full',
  {
    variants: {
      variant: {
        default: 'border bg-white text-slate-950 dark:bg-slate-950 dark:text-slate-50',
        destructive:
          'group border-red-500 bg-red-500 text-slate-50 dark:border-red-900 dark:bg-red-900 dark:text-slate-50',
        danger:
          'group border-red-500 bg-red-500 text-slate-50 dark:border-red-900 dark:bg-red-900 dark:text-slate-50',
        error:
          'group border-red-500 bg-red-500 text-slate-50 dark:border-red-900 dark:bg-red-900 dark:text-slate-50',
        info:
          'group border-slate-400 bg-slate-100 text-slate-900 dark:border-slate-900 dark:bg-slate-900 dark:text-slate-50',
        success:
          'group border-emerald-400 bg-emerald-100 text-emerald-900 dark:border-emerald-900 dark:bg-emerald-900 dark:text-emerald-50',
        warning:
          'group border-amber-400 bg-amber-100 text-amber-900 dark:border-amber-900 dark:bg-amber-900 dark:text-amber-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)
