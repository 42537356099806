import {getActivePinia, storeToRefs, } from 'pinia'
import {getCurrentInstance, watch, } from 'vue'

import {useUserStore, } from '@/js/modules/auth/user'

export function useUserStateSync () {
    let userStore = useUserStore(getActivePinia())
    const {currentUser, } = storeToRefs(userStore)
    const {proxy: vm, } = getCurrentInstance()

    vm.$page && watch(() => vm.$page, (newPage) => {
        if (newPage.props.loggedIn) {
            currentUser.value = newPage.props.currentUser
        } else {
            currentUser.value = null
        }
    }, {immediate: true, })

    return {
        currentUser,
    }
}
