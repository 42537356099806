<template>
    <div class="flex items-center space-x-2">
        <Switch
            :id="id"
            v-model:checked="inputValue"
        />
        <Label :for="id">
            <slot>
                {{ label }}
                <span
                    v-if="required"
                    class="text-red-700"
                >*</span>
            </slot>
        </Label>
        <div
            v-if="errorMsg"
            class="text-xs text-red-500"
        >
            {{ errorMsg }}
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, toRefs,} from 'vue'

import Label from '@/js/components/ui/label/Label.vue'
import Switch from '@/js/components/ui/switch/Switch.vue'

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: '',
    },
    errorMsg: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits([ 'update:modelValue', ])
const {modelValue, label, } = toRefs(props)

const inputValue = computed({
    get () {
        return modelValue.value
    },
    set (newValue) {
        emit('update:modelValue', newValue)
    },
})

const {uid,} = getCurrentInstance()
const id = computed(() => 'switch-' + uid)
</script>
