export const immanuelReverseUrl = (urlName, ...args) => {
    let url = window.routes[urlName]
    if (!url) {
        throw new Error('URL ' + urlName + ' was not found.')
    }

    //const args = arguments
    const argTokens = url.match(/<(\w+:)?\w+>/g)
    if (!argTokens && args[0] !== undefined && typeof (args[0]) !== 'object') {
        throw new Error(
            'Invalid URL lookup: URL ' + urlName + ' does not expect any arguments.'
        )
    }

    if (typeof (args[0]) == 'object' && !Array.isArray(args[0])) {
        let usedTokens = []
        if (argTokens) {
            argTokens.forEach(function (token) {
                let argName = token.slice(1, -1)

                if (argName.indexOf(':') > 0) {
                    argName = argName.split(':')[1]
                }

                const argValue = args[0][argName]
                if (argValue === undefined) {
                    throw new Error(
                        'Invalid URL lookup: Argument ' + argName + ' was not provided.'
                    )
                }
                usedTokens.push(argName)
                url = url.replace(token, argValue)
            })
        }
        let firstParam = true
        Object.keys(args[0]).forEach((key) => {
            if (usedTokens.indexOf(key) < 0) {
                if (firstParam) {
                    url += `?${key}=${args[0][key]}`
                } else {
                    url += `&${key}=${args[0][key]}`
                }
                firstParam = false
            }
        })
    } else if (args[0] !== undefined) {
        const zipped = args.map((e, i) => {
            return [ e, argTokens[i], ]
        })
        zipped.forEach(function (item) {
            let value = item[0]
            let token = item[1]
            if (token) {
                url = url.replace(token, value)
            } else if (typeof (value) == 'object') {
                let firstParam = true
                Object.keys(value).forEach((key) => {
                    if (firstParam) {
                        url += `?${key}=${value[key]}`
                    } else {
                        url += `&${key}=${value[key]}`
                    }
                    firstParam = false
                })
            }
        })
    }

    return url
}
