<script setup lang="ts">
import { ToastViewport, type ToastViewportProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<ToastViewportProps & { class?: string }>()
</script>

<template>
    <ToastViewport
        v-bind="props"
        :class="cn('fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]', props.class)"
    />
</template>
