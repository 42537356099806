<script setup lang="ts">
import {
    DialogContent,
    type DialogContentEmits,
    type DialogContentProps,
    DialogOverlay,
    DialogPortal,
    useEmitAsProps,
} from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<DialogContentProps & { class?: string }>()
const emits = defineEmits<DialogContentEmits>()

const emitsAsProps = useEmitAsProps(emits)
</script>

<template>
    <DialogPortal>
        <DialogOverlay
            class="
                fixed
                inset-0
                z-50
                bg-white/80
                backdrop-blur-sm
                data-[state=open]:animate-in
                data-[state=closed]:animate-out
                data-[state=closed]:fade-out-0
                data-[state=open]:fade-in-0
                dark:bg-slate-950/80
                "
        />
        <DialogContent
            :class="
                cn(
                    'fixed left-1/2 top-1/2 z-50 grid w-full max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 border border-slate-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:w-full dark:border-slate-800 dark:bg-slate-950',
                    props.class,
                )
            "
            v-bind="{ ...props, ...emitsAsProps }"
        >
            <slot />
        </DialogContent>
    </DialogPortal>
</template>
