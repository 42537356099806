import type {Element, Properties, } from 'hast'
import type {Strong, } from 'mdast'
import {type State, } from 'mdast-util-to-hast'

export default function strong (state: State, node: Strong & {attributes?: Properties}) {
  const result: Element = {
    type: 'element',
    tagName: 'strong',
    properties: node.attributes || {},
    children: state.all(node),
  }
  state.patch(node, result)
  return state.applyData(node, result)
}
