<template>
    <Dialog v-model:open="open">
        <DialogContent
            class="w-full grid-rows-[auto_minmax(0,1fr)_auto] p-0"
            :class="[
                fullscreen ? 'w-full max-w-full' : large ? 'max-w-3xl' : 'max-w-xl',
                fullscreen ? 'h-full max-h-full rounded-none' : 'h-dvh max-h-dvh rounded-lg md:h-fit md:max-h-[90dvh]'
            ]"
        >
            <DialogHeader class="p-6 pb-0">
                <slot name="header">
                    <DialogTitle class="flex items-center justify-between">
                        <h1 class="text-start rfs:text-2xl">
                            <slot name="title" />
                        </h1>

                        <Button
                            variant="ghost"
                            size="icon"
                            @click="open = false"
                        >
                            <X class="size-6" />
                            <span class="sr-only">Close</span>
                        </Button>
                    </DialogTitle>

                    <DialogDescription class="text-start">
                        <slot name="description" />
                    </DialogDescription>
                </slot>
            </DialogHeader>

            <div
                ref="dialog"
                class="grid h-full gap-4 overflow-y-auto px-6 py-4"
            >
                <slot name="body" />
            </div>

            <DialogFooter class="flex flex-wrap gap-x-4 gap-y-2 p-6 pt-0">
                <slot name="footer">
                    <Button
                        type="button"
                        @click="emit('cancel', null); hideDialog();"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        @click="emit('confirm', null); hideDialog();"
                    >
                        Ok
                    </Button>
                </slot>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>

<script setup lang="ts">
import {X,} from 'lucide-vue-next'
import {getActivePinia, storeToRefs,} from 'pinia'
import {onBeforeUnmount, onMounted, ref, watch,} from 'vue'

import { useDialogStore, } from '@/js/components/advanced/dialog/dialog'
import Button from '@/js/components/ui/button/Button.vue'
import {Dialog, DialogContent, DialogDescription, DialogFooter,DialogHeader, DialogTitle,} from '@/js/components/ui/dialog'

const emit = defineEmits<{
    (e: 'cancel', payload: null): void
    (e: 'confirm', payload: null): void
    (e: 'closed', payload: null): void
}>()

defineProps<{
    large?: boolean
    autoFocus?: boolean
    fullscreen?: boolean
}>()

const dialog = ref<HTMLElement>()
const open = ref(true)
const dialogStore = useDialogStore(getActivePinia())
const {close,} = storeToRefs(dialogStore)


const hideDialog = () => {
    emit('closed', null)
    open.value = false

    // after 200ms the backdrop is completely faded out, so we can reset the dialog store
    setTimeout(() => {
        dialogStore.$reset()
    }, 200)
}

watch(open, (value) => {
    if (!value) {
        hideDialog()
    }
})

watch(close, (value) => {
    if (value) {
        hideDialog()
    }
})

onMounted(() => {
    open.value = true

    setTimeout(() => {
        let input = dialog.value?.querySelector('input:first-of-type') as HTMLInputElement | null
        if (input === null) {
            input = dialog.value?.querySelector('textarea:first-of-type') as HTMLInputElement | null
        }
        if (input != null) {
            input.focus()
        }
    }, 300)
})
onBeforeUnmount(() => {
    hideDialog()
})
</script>
