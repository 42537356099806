<template>
    <nav class="container flex items-center gap-2 py-4">
        <TransitionLink
            :href="$route('vue_main.index')"
            class="me-auto"
        >
            <img
                src="@/static/img/immanuel-wetzlar-logo.png"
                class="inline-block h-12 object-contain align-top"
                :class="[transparent ? 'brightness-[3000]' : '']"
                alt="Immanuel Wetzlar Logo"
            >
        </TransitionLink>

        <div
            v-if="!transparent"
            class="hidden lg:block"
        >
            <Menubar
                v-if="!searchBarExpandend || $page.component === 'SearchIndex'"
                class="border-0"
            >
                <NavItem
                    v-for="menuItem in $page.props.navMenu"
                    :key="menuItem.href"
                    :menu-item="menuItem"
                />
                <MenubarMenu v-if="!($page.component === 'SearchIndex')">
                    <MenubarTrigger
                        as="button"
                        class="cursor-pointer"
                        aria-label="Suche öffnen"
                        @click="expandSearchBar(true)"
                    >
                        <Search class="size-5" />
                    </MenubarTrigger>
                </MenubarMenu>
            </Menubar>
            <div
                v-else
                class="flex gap-2"
            >
                <form
                    method="GET"
                    :action="$route('vue_search.index')"
                    novalidate
                    class="flex gap-2"
                    @submit.prevent="executeSearch()"
                >
                    <TextInput
                        id="q"
                        ref="searchBox"
                        v-model="query"
                        name="q"
                        type="text"
                        :placeholder="searchPlaceholder"
                        class="min-w-72"
                    >
                        <template #append>
                            <Button
                                variant="ghost"
                                type="submit"
                                aria-label="Suche starten"
                                class="me-0.5"
                            >
                                <Search class="size-5" />
                            </Button>
                        </template>
                    </TextInput>
                </form>

                <Button
                    variant="slate"
                    aria-label="Suche schließen"
                    @click="expandSearchBar(false)"
                >
                    <X class="size-5" />
                </Button>
            </div>
        </div>

        <div :class="transparent ? 'block' : 'block lg:hidden'">
            <Sheet>
                <SheetTrigger>
                    <Button
                        :variant="transparent ? 'ghost-dark' : 'ghost'"
                        size="icon"
                        class="size-12"
                        aria-label="Menü öffnen"
                    >
                        <Menu
                            class="size-6"
                        />
                    </Button>
                </SheetTrigger>
                <SheetContent class="w-full p-0 sm:max-w-full md:max-w-[50vw]">
                    <div class="container py-4">
                        <SheetHeader class="flex flex-row space-y-0">
                            <SheetTitle class="hidden">
                                Menü
                            </SheetTitle>
                            <SheetDescription class="hidden">
                                Hier findest du alle wichtigen Links.
                            </SheetDescription>

                            <TransitionLink
                                :href="$route('vue_main.index')"
                                class="me-auto"
                            >
                                <img
                                    src="@/static/img/immanuel-wetzlar-logo.png"
                                    class="inline-block h-12 align-top"
                                    alt="Immanuel Wetzlar Logo"
                                >
                            </TransitionLink>

                            <DialogClose
                                ref="closeButton"
                                :as="Button"
                                variant="outline"
                                size="icon"
                                class="ms-auto size-12"
                                aria-label="Menü schließen"
                            >
                                <X class="size-6" />
                            </DialogClose>
                        </SheetHeader>

                        <div class="flex flex-col divide-y py-8">
                            <NavItem
                                v-for="menuItem in $page.props.navMenu"
                                :key="menuItem.href"
                                :menu-item="menuItem"
                                drawer
                            />
                            <form
                                method="GET"
                                :action="$route('vue_search.index')"
                                novalidate
                                class="flex gap-2 p-2"
                                @submit.prevent="executeSearch()"
                            >
                                <TextInput
                                    id="q"
                                    ref="searchBox"
                                    v-model="query"
                                    name="q"
                                    type="text"
                                    class="min-w-72"
                                >
                                    <template #append>
                                        <Button
                                            variant="ghost"
                                            type="submit"
                                            aria-label="Suche starten"
                                            class="me-0.5"
                                        >
                                            <Search class="size-5" />
                                        </Button>
                                    </template>
                                </TextInput>
                            </form>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    </nav>
</template>

<script setup lang="ts">
import {router,} from '@inertiajs/vue3'
import {useMagicKeys, useWindowSize,} from '@vueuse/core'
import {Menu, Search, X,} from 'lucide-vue-next'
import {getCurrentInstance, ref, watch,} from 'vue'

import TextInput from '@/js/components/advanced/form/TextInput.vue'
import Button from '@/js/components/ui/button/Button.vue'
import {DialogClose,} from '@/js/components/ui/dialog'
import {Menubar, MenubarMenu, MenubarTrigger,} from '@/js/components/ui/menubar'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, } from '@/js/components/ui/sheet'
import NavItem from '@/js/shared/layout/NavItem.vue'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'
import {isMac,} from '@/js/shared/Utils'

defineProps<{
    transparent?: boolean
}>()

const {proxy: vm,} = getCurrentInstance()
const {width,} = useWindowSize()
const searchPlaceholder = ref(`Suchen... (${isMac() ? '⌘' : 'Strg'} + K)`)

const keys = useMagicKeys()
const hotkeySearch1 = keys['CTRL+K']
const hotkeySearch2 = keys['Meta+K']
watch([ hotkeySearch1, hotkeySearch2, ], (v) => {
    if (v[0] || v[1]) {
        expandSearchBar(true)
    }
})

const closeButton = ref()
watch(width, (newValue) => {
    if (newValue > 1024 && closeButton.value) {
        closeButton.value.$el.click()
    }
})

const query = ref('')
const searchBarExpandend = ref(false)
const searchBox = ref<HTMLElement | null>()

watch(() => vm.$page, (newValue) => {
    if (newValue.props.query) {
        query.value = ''
    }

    if (closeButton.value) {
        closeButton.value.$el.click()
    }
    searchBarExpandend.value = false
}, {deep: true,})

const expandSearchBar = (setTrue = false) => {
    searchBarExpandend.value = !searchBarExpandend.value || setTrue === true
    if (searchBarExpandend.value) {
        setTimeout(() => {
            searchBox.value.$el.querySelector('input')?.focus()
        }, 200)
    }
}
const executeSearch = () => {
    router.get(vm.$route('vue_search.index', {q: query.value,}))
}
</script>

<style lang="scss">
.navbar-enter-active,
.navbar-leave-active {
    transition: all 0.1s;
}

.navbar-enter-from,
.navbar-leave-to {
    transform: translateX(-10px);
    opacity: 0;
}
</style>
