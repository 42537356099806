<template>
    <nav class="bg-slate-900 text-slate-100">
        <div class="container flex flex-wrap items-center py-2">
            <div class="me-auto flex flex-wrap items-center pe-4">
                <template v-if="showAdminLinks">
                    <TransitionLink
                        class="flex items-center gap-1 underline"
                        :href="$route('vue_main.index')"
                    >
                        <HomeIcon class="size-5" />
                        Startseite
                    </TransitionLink>
                    <div class="mx-2">
                        ·
                    </div>
                    <TransitionLink
                        class="flex items-center gap-1 underline"
                        :href="$route('vue_admin.index')"
                    >
                        <CogIcon class="size-5" />
                        Einstellungen
                    </TransitionLink>
                </template>
            </div>

            <div class="flex flex-wrap items-center">
                <TransitionLink
                    class="flex items-center gap-1 underline"
                    :href="$route('vue_user.profile')"
                >
                    <img
                        v-if="currentUser.roles.includes('ROLE_CT_USER')"
                        src="@/static/img/churchtools_logo.svg"
                        class="h-4"
                        title="Angemeldet via ChurchTools"
                    >
                    <UserCircle
                        v-else
                        class="me-2 size-4"
                    />
                    {{ currentUser.username }}
                </TransitionLink>
                <div class="mx-2">
                    ·
                </div>
                <TransitionLink
                    class="underline"
                    :href="$route('vue_auth.logout')"
                >
                    Abmelden
                </TransitionLink>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import {CogIcon, HomeIcon, UserCircle,} from 'lucide-vue-next'
import {getActivePinia, storeToRefs,} from 'pinia'
import {computed,} from 'vue'

import {useUserStore,} from '@/js/modules/auth/user'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'

const userStore = useUserStore(getActivePinia())
const {currentUser,} = storeToRefs(userStore)

const showAdminLinks = computed(() => {
    return currentUser.value.roles.includes('ROLE_ADMIN') ||
        currentUser.value.roles.includes('ROLE_EDITOR') ||
        (currentUser.value.rights && currentUser.value.rights.length > 0)
})
</script>
