<script setup lang="ts">
import { DialogTitle, type DialogTitleProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<DialogTitleProps & { class?: string }>()
</script>

<template>
    <DialogTitle
        v-bind="props"
        :class="
            cn(
                'rfs:text-lg text-slate-950 rfs:font-medium leading-none tracking-tight dark:text-slate-50',
                props.class,
            )
        "
    >
        <slot />
    </DialogTitle>
</template>
