<template>
    <picture
        :class="pictureClass"
    >
        <transition
            v-if="transition"
            name="reveal-image"
            mode="out-in"
        >
            <div
                v-if="isLoading || isError"
                v-bind="$attrs"
            />
            <img
                v-else
                :src="webpRoute"
                v-bind="$attrs"
                :alt="defaultAlt"
                style="user-drag: none; user-select: none;"
            >
        </transition>
        <img
            v-else
            :src="webpRoute"
            v-bind="$attrs"
            :alt="defaultAlt"
            :style="focusStyle"
            style="user-drag: none; user-select: none;"
        >
    </picture>
</template>

<script setup lang="ts">
import {useImage,} from '@vueuse/core'
import {computed, getCurrentInstance, PropType, ref, toRefs, watch,} from 'vue'

import {MediaFile,} from '@/js/main.d'
import {Api,} from '@/js/shared/Api'
import {clone,} from '@/js/shared/Utils'

const {proxy: vm,} = getCurrentInstance()

const props = defineProps({
    image: {
        type: Object as PropType<MediaFile>,
        default: () => {},
    },
    imageId: {
        type: [ Number, String, ],
        default: 0,
    },
    imageUrl: {
        type: String,
        default: '',
    },
    options: {
        type: Object,
        default: () => {},
    },
    pictureClass: {
        type: [ String, Object, ],
        default: '',
    },
    alt: {
        type: String,
        default: '',
    },
    transition: {
        type: Boolean,
        default: false,
    },
    loadFocus: {
        type: Boolean,
        default: false,
    },
})
let {image, imageId, imageUrl, options: propOptions, alt, loadFocus,} = toRefs(props)
let options_ = ref(propOptions.value ? clone(propOptions.value) : {})

let webpRoute = ref('')
let fallbackRoute = ref('')

let defaultAlt = computed(() => {
    if (alt.value) {
        return alt.value
    }

    if (image.value) {
        return image.value.filename
    } else if (imageId.value) {
        return `Bild ${imageId.value}`
    } else if (imageUrl.value){
        return imageUrl.value
    } else {
        return 'Kein Bild'
    }
})
let focusStyle = computed(() => {
    if (options_.value.focus) {
        let res =  {
            'object-position': `${options_.value.focus[0] * 100}% ${options_.value.focus[1] * 100}%`,
        }
        return res
    }
    return {}
})

const setImageUrls = async () => {
    let id = image.value && image.value.id ? image.value.id : imageId.value

    if (id) {
        if ((!image.value || !image.value.id) && loadFocus.value) {
            // load focus asynchronously
            Api.get(vm.$route('api.media_by_id', imageId.value)).then(async (resp) => {
                if (resp.success) {
                    if (resp.file.target) {
                        options_.value.focus = resp.file.target
                    }
                }
            })
        }
        if (image.value && image.value.id) {
            if (image.value.target) {
                options_.value.focus = image.value.target
            }
        }

        options_.value.format = 'webp'
        webpRoute.value = vm.$route('media.resized', id, options_.value)
        options_.value.format = 'jpeg'
        fallbackRoute.value = vm.$route('media.resized', id, options_.value)
    } else if (imageUrl.value) {
        if (options_.value.noResize) {
            webpRoute.value = imageUrl.value
            return
        }

        options_.value.url = encodeURI(imageUrl.value)

        options_.value.format = 'webp'
        webpRoute.value = vm.$route('media.resized_url', options_.value)
        options_.value.format = 'jpeg'
        fallbackRoute.value = vm.$route('media.resized_url', options_.value)
    }
}
setImageUrls()
const {isLoading, error: isError,} = useImage({src: webpRoute.value,})

watch(props, () => setImageUrls(), {deep: true,})
</script>

<style lang="scss">
.reveal-image-enter-active,
.reveal-image-leave-active {
    transition: all 0.15s linear;
}

.reveal-image-enter-from,
.reveal-image-leave-to {
    opacity: 0;
}
</style>
