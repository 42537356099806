import {defineStore, } from 'pinia'

export const UserRights = {
    // [rightName, editorAllowed]
    RIGHT_ARCHIVE: [ 'RIGHT_ARCHIVE', true, ],
    RIGHT_KIOSK: [ 'RIGHT_KIOSK', true, ],
    RIGHT_GENERAL_SETTINGS: [ 'RIGHT_GENERAL_SETTINGS', true, ],
    RIGHT_PAGES: [ 'RIGHT_PAGES', true, ],
    RIGHT_CALENDAR: [ 'RIGHT_CALENDAR', true, ],
    RIGHT_PERSONS: [ 'RIGHT_PERSONS', true, ],
    RIGHT_REGISTRATIONS: [ 'RIGHT_REGISTRATIONS', true, ],
    RIGHT_USERS: [ 'RIGHT_USERS', false, ],
}

export const useUserStore = defineStore('user', {
    state () {
        return {
            currentUser: {},
        }
    },
})
