<script setup lang="ts">
import { Label, type LabelProps, } from 'radix-vue'

import { cn, } from '@/js/lib/utils'

const props = defineProps<LabelProps & { class?: string }>()
</script>

<template>
    <Label
        v-bind="props"
        :class="
            cn(
                'block text-sm tracking-tight font-medium text-slate-950 text-left dark:text-slate-50',
                props.class,
            )
        "
    >
        <slot />
    </Label>
</template>
