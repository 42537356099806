<template>
    <template v-if="!child && menuItem.children.length > 0">
        <Collapsible
            v-if="drawer"
            v-model:open="isOpen"
        >
            <CollapsibleTrigger
                class="block w-full"
            >
                <div class="flex w-full items-center justify-between p-2 font-medium">
                    {{ menuItem.title }}
                    <ChevronDown
                        class="ms-1 size-5"
                        :class="{
                            'rotate-180': isOpen,
                        }"
                    />
                </div>
            </CollapsibleTrigger>
            <CollapsibleContent>
                <NavItem
                    v-for="item in menuItem.children"
                    :key="item.href"
                    :menu-item="item"
                    child
                    drawer
                />
            </CollapsibleContent>
        </Collapsible>
        <MenubarMenu v-else>
            <MenubarTrigger
                class="cursor-pointer"
                as="a"
            >
                {{ menuItem.title }}
                <ChevronDown class="ms-1 size-4" />
            </MenubarTrigger>
            <MenubarContent>
                <NavItem
                    v-for="item in menuItem.children"
                    :key="item.href"
                    :menu-item="item"
                    child
                />
            </MenubarContent>
        </MenubarMenu>
    </template>
    <template v-else-if="child">
        <component
            :is="menuItem.isVue ? TransitionLink : 'a'"
            v-if="drawer"
            :href="menuItem.href"
            class="block w-full rounded p-2 hover:bg-slate-100"
        >
            {{ menuItem.title }}
        </component>
        <MenubarItem
            v-else
            :as="menuItem.isVue ? TransitionLink : 'a'"
            :href="menuItem.href"
            class="cursor-pointer"
        >
            {{ menuItem.title }}
        </MenubarItem>
    </template>
    <template v-else>
        <component
            :is="menuItem.isVue ? TransitionLink : 'a'"
            v-if="drawer"
            :href="menuItem.href"
            class="block w-full rounded p-2 hover:bg-slate-100"
        >
            {{ menuItem.title }}
        </component>
        <MenubarMenu v-else>
            <MenubarTrigger
                :as="menuItem.isVue ? TransitionLink : 'a'"
                :href="menuItem.href"
                class="cursor-pointer"
            >
                {{ menuItem.title }}
            </MenubarTrigger>
        </MenubarMenu>
    </template>
</template>

<script setup lang="ts">
import {ChevronDown,} from 'lucide-vue-next'
import {ref,} from 'vue'

import {Collapsible, CollapsibleContent, CollapsibleTrigger,} from '@/js/components/ui/collapsible'
import {MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger,} from '@/js/components/ui/menubar'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'

defineProps({
    menuItem: {
        type: Object,
        default: () => {},
    },
    child: {
        type: Boolean,
        default: false,
    },
    drawer: {
        type: Boolean,
        default: false,
    },
})

const isOpen = ref(false)
</script>
