<template>
    <Toaster v-if="isMounted" />
</template>

<script setup lang="ts">
import {getCurrentInstance, onMounted, ref, watch, } from 'vue'

import {useToast,} from '@/js/components/ui/toast'
import Toaster from '@/js/components/ui/toast/Toaster.vue'

const {proxy: vm, } = getCurrentInstance()
const isMounted = ref(false)

onMounted(() => {
    isMounted.value = true
})

const {toast,} = useToast()

vm.$page && watch(() => vm.$page, (newPage) => {
    if (newPage.props.flashBag) {
        newPage.props.flashBag.forEach((value) => {
            toast({
                title: value[1],
                variant: value[0],
            })
        })
    }
}, {immediate: true,})
</script>
