<template>
    <Link v-bind="attrs">
        <slot />
    </Link>
</template>

<script setup lang="ts">
import { Link, } from '@inertiajs/vue3'
import {getActivePinia, storeToRefs,} from 'pinia'

import {useDialogStore,} from '@/js/components/advanced/dialog/dialog'
import {usePageTransitionStore,} from '@/js/shared/transition/pageTransition'

const pageTransitionStore = usePageTransitionStore(getActivePinia())
let {transitioning,} = storeToRefs(pageTransitionStore)

const dialogStore = useDialogStore(getActivePinia())
const show = () => {
    transitioning.value = true
    dialogStore.closeDialog()
}

const hide = () => {
    transitioning.value = false
}

const attrs = {
    onStart: show,
    onFinish: hide,
}
</script>
