import {ServiceWorkerRegError, } from '@/js/shared/Utils'

let global = (1, eval)('this')

global.hasLocalStorageAccess = false
try {
    global.localStorage
    global.hasLocalStorageAccess = true
} catch (e) {
    console.warn('No local storage access')
}

if ('serviceWorker' in navigator && global.hasLocalStorageAccess) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then(() => {
        }).catch((registrationError) => {
            throw new ServiceWorkerRegError(registrationError)
        })
    })
}
