<template>
    <ImmanuelDialog large>
        <template #title>
            Datenschutzeinstellungen
        </template>
        <template #body>
            <Alert>
                Hier kannst Du einsehen und anpassen, welche Information wir oder Dritte über Dich speichern.
                Weitere Details findest du in unserer
                <TransitionLink
                    :href="$route('vue_page.view', 'datenschutz')"
                    class="text-main1 underline hover:no-underline"
                >
                    Datenschutzerklärung.
                </TransitionLink>
            </Alert>

            <div class="mt-4 flex flex-col gap-2">
                <SwitchInput v-model="ga">
                    <div>
                        <strong>Google Analytics</strong>
                    </div>
                    <div>
                        <em>Erhebung von Besucher-Statistiken</em>
                    </div>
                </SwitchInput>
                <SwitchInput
                    v-model="gm"
                    as-switch
                >
                    <div>
                        <strong>Google Maps</strong>
                    </div>
                    <div>
                        <em>Anzeige von Karten im Footer und auf weiteren Seiten</em>
                    </div>
                </SwitchInput>
                <SwitchInput v-model="gr">
                    <div>
                        <strong>Google ReCaptcha</strong>
                    </div>
                    <div>
                        <em>Spamschutz von Formularen</em>
                    </div>
                </SwitchInput>
                <SwitchInput v-model="sp">
                    <div>
                        <strong>Spotify</strong>
                    </div>
                    <div>
                        <em>Abspielen des Podcasts</em>
                    </div>
                </SwitchInput>
                <SwitchInput v-model="tw">
                    <div>
                        <strong>Twitch</strong>
                    </div>
                    <div>
                        <em>Abspielen des Livestreams</em>
                    </div>
                </SwitchInput>
                <SwitchInput v-model="yt">
                    <div>
                        <strong>YouTube</strong>
                    </div>
                    <div>
                        <em>Abspielen des Predigtvideos</em>
                    </div>
                </SwitchInput>

                <hr class="my-4">

                <SwitchInput v-model="consentAll">
                    <div>
                        <strong>Alle aktivieren oder deaktivieren</strong>
                    </div>
                </SwitchInput>
            </div>
        </template>
        <template #footer>
            <Button
                variant="outline-dark"
                class="grow"
                @click="declineAll(); dialogStore.closeDialog();"
            >
                Ablehnen
            </Button>
            <Button
                variant="outline-primary"
                class="grow"
                @click="saveSelection(); dialogStore.closeDialog();"
            >
                Auswahl speichern
            </Button>
            <Button
                variant="primary"
                class="grow"
                @click="acceptAll(); dialogStore.closeDialog();"
            >
                Alle akzeptieren
            </Button>
        </template>
    </ImmanuelDialog>
</template>

<script setup lang="ts">
import {getActivePinia, storeToRefs, } from 'pinia'
import {computed, } from 'vue'

import {useDialogStore,} from '@/js/components/advanced/dialog/dialog'
import ImmanuelDialog from '@/js/components/advanced/dialog/Dialog.vue'
import SwitchInput from '@/js/components/advanced/form/SwitchInput.vue'
import {Alert,} from '@/js/components/ui/alert'
import {Button,} from '@/js/components/ui/button'
import {useConsentStore,} from '@/js/shared/consent/consent'
import TransitionLink from '@/js/shared/layout/TransitionLink.vue'

const dialogStore = useDialogStore(getActivePinia())
const consentStore = useConsentStore(getActivePinia())
let {googleAnalytics, googleMaps, googleRecaptcha, spotify, twitch, youtube, showBanner,} = storeToRefs(consentStore)

let ga = computed({
    get () {
        return googleAnalytics.value
    },
    set (newValue){
        consentStore.setGoogleAnalytics(newValue, false)
    },
})

let gm = computed({
    get () {
        return googleMaps.value
    },
    set (newValue){
        consentStore.setGoogleMaps(newValue, false)
    },
})

let gr = computed({
    get () {
        return googleRecaptcha.value
    },
    set (newValue){
        consentStore.setGoogleRecaptcha(newValue, false)
    },
})

let sp = computed({
    get () {
        return spotify.value
    },
    set (newValue){
        consentStore.setSpotify(newValue, false)
    },
})

let tw = computed({
    get () {
        return twitch.value
    },
    set (newValue){
        consentStore.setTwitch(newValue, false)
    },
})

let yt = computed({
    get () {
        return youtube.value
    },
    set (newValue){
        consentStore.setYoutube(newValue, false)
    },
})

let consentAll = computed({
    get () {
        return consentStore.allAccepted
    },
    set (newValue) {
        consentStore.setAll(newValue, false)
    },
})

const declineAll = () => {
    consentStore.setAll(false)
    showBanner.value = false
}

const saveSelection = () => {
    consentStore.save()
    showBanner.value = false
}

const acceptAll = () => {
    consentStore.setAll(true)
    showBanner.value = false
}
</script>
