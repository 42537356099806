<template>
    <div class="grid w-full items-center gap-1.5">
        <Label
            v-if="label"
            :for="id"
        >
            <slot>
                {{ label }}
                <span
                    v-if="required"
                    class="text-red-700"
                >*</span>
            </slot>
        </Label>
        <div class="relative">
            <Input
                :id="id"
                v-bind="$attrs"
                v-model="inputValue"
                :type="type"
                :placeholder="placeholder ?? undefined"
            />
            <div class="absolute inset-y-0 right-0 flex items-center">
                <slot name="append" />
            </div>
        </div>
        <div
            v-if="errorMsg"
            class="text-xs text-red-500"
        >
            {{ errorMsg }}
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, getCurrentInstance, toRefs,} from 'vue'

import Input from '@/js/components/ui/input/Input.vue'
import Label from '@/js/components/ui/label/Label.vue'

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text',
    },
    placeholder: {
        type: String,
        default: '',
    },
    errorMsg: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits([ 'update:modelValue', ])
const {modelValue, label, } = toRefs(props)

const inputValue = computed({
    get () {
        return modelValue.value
    },
    set (newValue) {
        emit('update:modelValue', newValue)
    },
})

const {uid,} = getCurrentInstance()
const id = computed(() => 'switch-' + uid)
</script>
