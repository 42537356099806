<template>
    <div
        class="fixed inset-x-0 bottom-0 border-t border-pink3 bg-pink4/50 py-2 backdrop-blur"
        style="backdrop-filter: blur(12px);"
    >
        <div class="container flex max-w-3xl items-center">
            <div class="me-2">
                <Button
                    variant="primary"
                    class="size-16 rounded-full"
                    @click="playing = !playing"
                >
                    <Pause
                        v-if="playing"
                        class="size-12 translate-x-px"
                        aria-label="Pause"
                    />
                    <Play
                        v-else
                        class="size-12 translate-x-0.5"
                        aria-label="Abspielen"
                    />
                </Button>
            </div>
            <div
                class="grow"
                style="min-width: 0;"
            >
                <div
                    class="mb-2 flex items-center"
                    style="min-width: 0;"
                >
                    <button
                        class="truncate hover:underline"
                        @click="router.visit(returnLink)"
                    >
                        {{ title }}
                    </button>
                    <div class="ms-auto">
                        <X
                            class="size-5 cursor-pointer"
                            aria-label="Schließen"
                            @click="resetAudio()"
                        />
                    </div>
                </div>
                <Scrubber
                    v-model="currentTime"
                    :secondary="bufferEnd"
                    :max="duration"
                    simple
                />
                <div class="mt-2 flex items-center gap-2">
                    <Button
                        variant="outline-dark"
                        size="sm"
                        class="h-8 w-10"
                        @click="currentTime = Math.max(0, currentTime - 15)"
                    >
                        <Rewind class="size-4" />
                    </Button>
                    <Button
                        variant="outline-dark"
                        size="sm"
                        class="h-8 w-12"
                        @click="nextSpeed"
                    >
                        {{ speeds[speedIndex] }}x
                    </Button>
                    <Button
                        variant="outline-dark"
                        size="sm"
                        class="h-8 w-10"
                        @click="currentTime = Math.min(duration, currentTime + 15)"
                    >
                        <FastForward class="size-4" />
                    </Button>
                    <code class="ms-auto text-end">
                        {{ formatDuration(currentTime) }} / {{ formatDuration(duration) }}
                    </code>
                </div>
            </div>
        </div>
    </div>
    <audio
        ref="audio"
        class="hidden"
    />
</template>

<script setup lang="ts">
import {router,} from '@inertiajs/vue3'
import { FastForward, Pause, Play, Rewind, X,} from 'lucide-vue-next'
import {getActivePinia, storeToRefs,} from 'pinia'
import {computed, onMounted, ref, } from 'vue'

import {Button,} from '@/js/components/ui/button'
import Scrubber from '@/js/shared/form/Scrubber.vue'
import {useGlobalAudioPlayer,} from '@/js/shared/media/audio'

const audio = ref()
const audioStore = useGlobalAudioPlayer(getActivePinia())
const {title, playing, duration, buffered, rate, currentTime, returnLink,} = storeToRefs(audioStore)

const bufferEnd = computed(() => buffered.value.length > 0 ? buffered.value[buffered.value.length - 1][1] : 0)
const formatDuration = (sec) => {
    return new Date(1000 * sec).toISOString().slice(14, 19)
}

const resetAudio = () => {
    playing.value = false
    audioStore.reset()
}

let speeds = ref([
    1,
    1.5,
    2,
])
let speedIndex = ref(0)
const nextSpeed = () => {
    speedIndex.value++
    if (speedIndex.value > 2) {
        speedIndex.value = 0
    }
    rate.value = speeds.value[speedIndex.value]
}


onMounted(() => {
    audioStore.loadAudio(audio.value)
})
</script>
