<script setup lang="ts">
import { cn, } from '@/js/lib/utils'

import { alertVariants, } from '.'

interface Props {
    variant?: NonNullable<Parameters<typeof alertVariants>[0]>['variant']
    class?: string
}

const props = defineProps<Props>()
</script>

<template>
    <div :class="cn(alertVariants({ variant }), props.class ?? '')">
        <slot />
    </div>
</template>
